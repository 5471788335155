@use 'fonts';
@use 'variables';
@use 'reset';
@use 'material';
@use 'scrollbar';
@use 'default';
@use 'mat-custom-styling';
@use 'resize.directive';

.input {
  box-sizing: border-box;
  width: 100%;
  padding: 15px 10px;
  border: solid 2px var(--sys-outline);
  border-radius: 16px;
  background: none;
  color: var(--sys-on-surface);
  transition: border-color 0.3s ease;

  &:focus {
    border-color: var(--sys-primary);
    outline: none;
  }
}

/* stylelint-disable-next-line */
#streamingVideo {
  top: 0;
  left: 0;
  object-fit: cover !important;
}

.context-menu__rect {
  fill: var(--sys-surface-container-high);
}

.content-min-height {
  min-height: 375px;
}
