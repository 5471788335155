.resize {
  --offset-scroll: 5px;

  &.resize_has-scroll {
    --offset-scroll: 10px;
  }

  &__container {
    display: flex;
    z-index: 50;
    position: absolute;
    top: 0;
    right: var(--offset-scroll);
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 100%;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
    transition: 0.3s opacity;
  }

  &__resizer {
    z-index: 1;
    position: relative;
    width: 5px;
    height: 54px;
    border-right: 2px solid var(--sys-on-surface-variant);
    border-left: 2px solid var(--sys-on-surface-variant);
    cursor: col-resize;
    opacity: 0.25;
    user-select: none;

    &::after {
      display: block;
      position: absolute;
      right: -12px;
      width: 24px;
      height: 100%;
      content: '';
    }

    &_left {
      right: auto;
      left: 0;
    }

    &_hide {
      display: none;
      pointer-events: none;
    }
  }
}
