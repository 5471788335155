@use '@angular/material' as mat;

.light-theme,
.dark-theme {
  --mdc-dialog-container-shape: 12px;
}

@include mat.elevation-classes();
@include mat.app-background();

// https://github.com/angular/components/blob/main/guides/duplicate-theming-styles.md
mat.$theme-ignore-duplication-warnings: true;

.light-theme {
  @include mat.all-component-themes(
    mat.define-theme(
      (
        color: (
          primary: mat.$azure-palette,
          tertiary: mat.$blue-palette,
        ),
      )
    )
  );
}

.dark-theme {
  @include mat.all-component-themes(
    mat.define-theme(
      (
        color: (
          theme-type: dark,
          primary: mat.$azure-palette,
          tertiary: mat.$blue-palette,
        ),
      )
    )
  );
}

.toolbar-menu__tooltip {
  .mdc-tooltip__surface {
    max-width: 220px;
  }
}
