// M3/title/large
@mixin title-large {
  font-weight: 400;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 0;
}

// M3/title/medium
@mixin title-medium {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
}

// M3/title/small
@mixin title-small {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
}

// M3/body/large;
@mixin body-large {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
}

// M3/body/medium
@mixin body-medium {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
}

// M3/body/small
@mixin body-small {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0;
}

// M3/headline/large
@mixin headline-large {
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 32px;
}

@mixin headline-small {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
}

// M3/label/small
@mixin label-small {
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.5px;
}

@mixin label-large {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.5px;
}

@mixin light-text {
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.5px;
}

@mixin link-medium {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  text-decoration-line: underline;
}

@mixin text($type, $size) {
  @if $type == 'title' {
    @if $size == 'large' {
      @include title-large;
    }

    @if $size == 'medium' {
      @include title-medium;
    }

    @if $size == 'small' {
      @include title-small;
    }
  }

  @if $type == 'body' {
    @if $size == 'large' {
      @include body-large;
    }

    @if $size == 'medium' {
      @include body-medium;
    }

    @if $size == 'small' {
      @include body-small;
    }
  }

  @if $type == 'headline' {
    @if $size == 'large' {
      @include headline-large;
    }

    @if $size == 'small' {
      @include headline-small;
    }
  }

  @if $type == 'label' {
    @if $size == 'small' {
      @include label-small;
    }
  }

  @if $type == 'link' {
    @if $size == 'medium' {
      @include link-medium;
    }
  }
}
