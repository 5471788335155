::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-track {
  border-radius: 4px;
  background-color: var(--scroll-track);
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: var(--scroll-thumb);
}

$font-size: 16;
$scroll-size: calc(7 / $font-size) + rem;

.scrollbar-container {
  display: block;
  position: relative;
  height: 100%;
  max-height: inherit;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &:hover {
    .scrollbar-v-line,
    .scrollbar-h-line {
      opacity: 1;
    }
  }
}

.scrollbar {
  display: flex;
  z-index: 999;
  position: sticky;
  top: calc(4 / $font-size) + rem;
  right: calc(4 / $font-size) + rem;
  bottom: calc(4 / $font-size) + rem;
  user-select: none;

  &-v {
    left: 0;
    justify-content: flex-end;
    height: 0;

    &-line {
      z-index: 999;
      position: relative;
      margin-right: calc(4 / $font-size) + rem;
      overflow: hidden;
      border-radius: calc(4 / $font-size) + rem;
      opacity: 0;
      transition: opacity 0.3s ease;

      &:active {
        opacity: 1;
      }

      &-inner {
        width: 7px;
        height: 100%;
        background-color: var(--scroll-track);
      }
    }

    &-thumb {
      position: absolute;
      top: 0;
      width: 100%;
      border-radius: 4px;
      background-color: var(--scroll-thumb);
    }
  }

  &-h {
    bottom: calc(4 / $font-size) + rem;
    left: calc(4 / $font-size) + rem;
    width: calc(100% - $scroll-size);
    height: $scroll-size;

    &-line {
      position: relative;
      width: 100%;
      height: $scroll-size;
      margin-bottom: calc(4 / $font-size) + rem;
      overflow: hidden;
      border-radius: calc(4 / $font-size) + rem;
      opacity: 0;
      transition: opacity 0.3s ease;

      &:active {
        opacity: 1;
      }

      &-inner {
        width: 100%;
        height: $scroll-size;
        background-color: var(--scroll-track);
      }
    }

    &-thumb {
      position: absolute;
      top: 0;
      width: 100%;
      height: $scroll-size;
      border-radius: calc(4 / $font-size) + rem;
      background-color: var(--scroll-thumb);
    }
  }
}
