:root {
  --transition-time: 0.25s;
  --font-family-base: Roboto, sans-serif;
  --ref-secondary-secondary-40: #545f71;
  --ref-secondary-secondary0: #000;
  --ref-secondary-white: #fff;
  --important: #f14755;
  --box-shadow: 0 8px 12px 6px rgba(0, 0, 0, 0.15), 0 4px 4px 0 rgba(0, 0, 0, 0.3);

  .light-theme {
    --base: #fff;
    --state-layers: #000;
    --state-layers-on-surface-variant: #49454f;
    --sys-primary: #005fad;
    --sys-on-primary: #fff;
    --sys-surface: #faf9fd;
    --sys-on-surface: #1a1c1e;
    --sys-surface-container: #eeedf1;
    --sys-surface-container-low: #f4f3f7;
    --sys-surface-container-high: #e8e8eb;
    --sys-surface-container-highest: #e3e2e6;
    --sys-invert-surface: #2f3033;
    --sys-surface-bright: #faf9fd;
    --sys-inverse-surface: #2f3033;
    --sys-on-surface-variant: #43474e;
    --sys-outline: #73777f;
    --sys-error: #ba1a1a;
    --sys-on-error: #690005;
    --sys-on-error-container: #410002;
    --sys-on-tertiary-container: #271430;
    --elevation-light-3: 0px 1px 3px 0px rgba(0, 0, 0, 0.3), 0px 4px 8px 3px rgba(0, 0, 0, 0.15);
    --scroll-track: #dadce0;
    --scroll-thumb: #8f8f8f;
    --slide-border: #005fad;
    --context-menu-hover: rgba(26, 27, 31, 0.08);
  }

  .dark-theme {
    --base: #000;
    --state-layers: #fff;
    --state-layers-on-surface-variant: #cac4d0;
    --sys-primary: #a4c8ff;
    --sys-on-primary: #00315d;
    --sys-surface: #121316;
    --sys-on-surface: #c6c6ca;
    --sys-surface-container: #1e2022;
    --sys-surface-container-low: #1a1c1e;
    --sys-surface-container-high: #292a2d;
    --sys-surface-container-highest: #343538;
    --sys-invert-surface: #e3e2e6;
    --sys-surface-bright: #38393c;
    --sys-inverse-surface: #e3e2e6;
    --sys-on-surface-variant: #c3c6cf;
    --sys-outline: #8d9199;
    --sys-error: #ffb4ab;
    --sys-on-error: #fff;
    --sys-on-error-container: #ffdad6;
    --sys-on-tertiary-container: #f6d9ff;
    --elevation-light-3: 0px 1px 3px 0px rgba(255, 255, 255, 0.2), 0px 4px 8px 3px rgba(255, 255, 255, 0.1);
    --scroll-track: #292a2d;
    --scroll-thumb: #8f8f8f;
    --slide-border: #adc6ff;
    --context-menu-hover: rgba(227, 226, 230, 0.08);
  }
}
