@use 'typography';

.mdc-tooltip__surface {
  background-color: var(--sys-on-surface) !important;
  color: var(--sys-surface) !important;
  text-align: center !important;
}

.medium {
  .mdc-tooltip__surface {
    max-width: 260px !important;
  }
}

.large {
  .mdc-tooltip__surface {
    max-width: 350px !important;
  }
}

.mat-mdc-checkbox-ripple,
.mdc-checkbox__ripple {
  display: none;
}

.mdc-label {
  font-weight: 400;
}

.mdc-switch.mdc-switch--unselected:enabled .mdc-switch__icon {
  transform: scale(0.8); // This is optional for a better icon fit

  // Toggle off
  &--off > path {
    d: path('M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z');
  }
}

.mdc-snackbar {
  .mdc-snackbar__surface {
    min-width: inherit;
    max-width: none;

    &:has(.notification) {
      padding-right: 0;
      padding-left: 4px;
      background-color: var(--sys-primary);

      &:has(.has-bottom-offset) {
        transform: translateY(-22px);
      }
    }

    &:has(.notification.error) {
      background-color: var(--sys-error);
    }
  }

  &.error {
    .mdc-snackbar__surface {
      background: var(--sys-surface);
    }

    .mat-mdc-snack-bar-label {
      color: var(--sys-error);
    }
  }
}

.cdk-overlay-container {
  z-index: 100;

  .mat-mdc-menu-panel {
    border-radius: 8px;
    background-color: var(--sys-surface);

    &.object-card-menu {
      z-index: 0;
      position: relative;
      top: -34px;
      left: -43px;
      width: 200px;
    }
  }

  .object-card-menu {
    &__title {
      padding: 8px 12px;
      color: var(--state-layers);

      @include typography.text('title', 'medium');
    }

    &__btn {
      box-sizing: border-box;
      display: flex;
      z-index: 0;
      position: relative;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      min-height: 48px;
      margin: 0;
      padding-left: 48px;
      overflow: hidden;
      border: none;
      outline: none;
      color: var(--sys-on-surface);
      font-size: inherit;
      text-align: left;
      text-decoration: none;
      cursor: pointer;
      transition: background-color var(--transition-time);
      user-select: none;

      &:hover {
        background-color: var(--mat-menu-item-hover-state-layer-color);
      }

      &_active {
        .object-card-menu__btn-icon {
          opacity: 1;
        }
      }

      &-icon {
        width: 24px;
        height: 24px;
        fill: var(--sys-on-surface);
        z-index: 1;
        position: absolute;
        top: 50%;
        left: 12px;
        transform: translateY(-50%);
        opacity: 0;
      }

      &-text {
        @include typography.text('body', 'large');
      }
    }
  }
}

.folder-submenu {
  width: 368px;
  max-width: unset !important;
  border: 1px solid var(--sys-surface-container-highest);
  border-radius: 16px !important;
  background-color: var(--sys-surface-container);

  .mat-mdc-menu-item-text {
    display: flex;
    column-gap: 12px;
    align-items: center;
  }
}

@media screen and (height <= 480px) {
  .light-button-tooltip {
    top: -15px;
    left: -55px;
  }
}

.add-snapshot__btn .mdc-icon-button__ripple.mat-mdc-button-persistent-ripple {
  border-radius: 0;
}

.demo-mode-details__context-menu {
  min-width: 200px;
  min-height: 130px;
  border-radius: 4px;
  background-color: var(--sys-surface-container) !important;
}

app-layers-card,
app-layers-object-card {
  .mat-mdc-tab-header-pagination {
    border-bottom-width: var(--mat-tab-header-divider-height, 1px);
    border-bottom-style: solid;
    border-bottom-color: var(--mat-tab-header-divider-color, var(--mat-sys-surface-variant));
  }
}

.generate-panel-tooltip .mat-mdc-tooltip-surface {
  max-height: 60vh;
}
